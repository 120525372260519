import { React } from 'react';
import DelayLink from 'react-delay-link';
import './p3.css';
const P3 = ({props,doo}) => {
    return (
    	<div className="project">
        <DelayLink to='/projectDetails3' delay={1200}>
            <div className="project3 link hello" onClick={doo}>
                <img src='https://thumbs.dreamstime.com/z/steps-legacy-application-migration-174967860.jpg' />
            </div>
        </DelayLink>
            <h5>Legacy Application Migration</h5>
            <p></p>
    	</div>
    );
}

export default P3;