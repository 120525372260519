import { React, useEffect, useState } from 'react';
import $ from 'jquery';
import { motion, useMotionValue, useSpring  } from "framer-motion";
import TagCloud from 'TagCloud';
import './about.css';
import './turn.css';
import headphone from '../../img/hobbies/headphones.svg';
import telescope from '../../img/hobbies/telescope.svg';
import cat from '../../img/hobbies/cat.gif';
import man from '../../img/hobbies/man.gif';
import monkey from '../../img/hobbies/monkey.gif';
import travelbg from '../../img/hobbies/tourbg.jpg';
import plane from '../../img/hobbies/plane.svg';
import DCover from '../../img/hobbies/diary-covers.jpg';
import rb from '../../img/hobbies/aboutus.png';
import theend from '../../img/hobbies/theend.png';
import ayan from '../../img/hobbies/wwd.png';
import Modal from '../../components/cv/Resume';

import HTMLFlipBook from "react-pageflip";
import {isMobile} from 'react-device-detect';

const About = () => {
    const [isOpen, toggle] = useState(false);
	function handlOpenModal1(open) {
		toggle(open);
	}
    
    const [isModal2Open, toggleModal2] = useState(false);
	function handlOpenModal2(open) {
		toggleModal2(open);
	}
    
    const containerTC = '.tagcloudCon';
    const textsTC = [
        'Html', 'Expo', 'JavaScript',
        'C', 'Python', 'React', 'JQuery',
        'Node', 'Flask', 'Figma',
        'MS Excel', 'SQL', 'Powerpoint',
        'Dev C++','React Native',
        'Ngrok', 'Anaconda', 'MS Word',
        'Eclipse', 'Gambus', 'WireShark',
        'Visual Basic', 'Miniconda',
        'Framer Motion', 'Bootstrap', 'SVG',
        'Git', 'Github', 'Postman',
        'Tachyons', 'JSON', 'Accuantix',
        'Photopia', 'Knex', 'Android Studio',
        'Css', 'React Native', 'API',
        'Gsap','Animaker',
    ];
    const optionsTC = {radius:170,maxSpeed:'fast'};
    TagCloud(containerTC, textsTC, optionsTC);

    const options1 = {
        width: 700,
        height: 400,
        display: "double",
        acceleration: true,
        elevation: 50,
        gradients: !$.isTouch,
        when: {
            turned: function(e, page) {
            console.log("Current view: ", $(this).turn("view"));
            }
        }
    };
    const panel1Y = useMotionValue(0);
    const panel2Y = useMotionValue(200);
    const panel3Y = useMotionValue(400);
    const springConfig = { damping: 25, stiffness: 700 };
    const panel1YSpring = useSpring(panel1Y, springConfig);
    const panel2YSpring = useSpring(panel2Y, springConfig);
    const panel3YSpring = useSpring(panel3Y, springConfig);

    useEffect(()=>{
        if (isMobile) {
            $('.sidePage').addClass('active')
        }
    },[])

    useEffect(()=>{
        var acc = document.getElementsByClassName("accordionBtn");
        for (let i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            $(this).toggleClass('opened');
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                panel.style.padding='0 0';
                if(i==0){
                    panel1YSpring.set(400);
                }
                else if(i==1){
                    panel2YSpring.set(200);
                }
                else if(i==2){
                    panel3YSpring.set(400);
                }
            } else {
                panel.style.maxHeight = 2000 + "px";
                panel.style.padding='30px 0';
                if(i==0){
                    panel1YSpring.set(0);
                }
                else if(i==1){
                    panel2YSpring.set(0);
                }
                else if(i==2){
                    panel.style.padding='80px 0';
                    panel3YSpring.set(0);
                }
            } 
        });
        }
    },[]);
    var loadStick1={'--i':0};
    var loadStick2={'--i':1};
    var loadStick3={'--i':2};
    var loadStick4={'--i':3};
    var loadStick5={'--i':4};

    return (
    	<div className="darkBg section" id="TechnologyStack">
            <Modal isOpen={isOpen} handleClose={() => handlOpenModal1(false)}>
                <h6>My Resume</h6>
                <iframe src='https://www.youtube.com/embed/48deIoNls_E' className='videoCVIf'></iframe>
            </Modal>
            <Modal isOpen={isModal2Open} handleClose={() => handlOpenModal2(false)}>
                <h6>
                    My Resume &nbsp;
                    
                </h6>
                
            </Modal>
            <div className="lines container-fluid">
                <div className="row h-100">
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                </div>
            </div>
            <br/>
            <span className='phead'>
                
            </span>
            <div className='accordions'>
                <div className='accordionBtn circle-plus closed opened active' id='AboutSandE'>
                    <div className='accordionBtnCircle'>
                        <span><img src="https://img.icons8.com/ios-filled/35/ffffff/settings.png"/></span>
                    </div>
                    <h4>Technology Stacks</h4>
                    <div>
                        <div className="circle">
                            <div className="horizontal"></div>
                            <div className="vertical"></div>
                        </div>
                    </div>
                </div>
                <motion.div className='accordionBody' style={{translateY: panel1YSpring,maxHeight:'2000px',padding:'30px 0'}}>
                    <div className='SandE'>
                        <div className="tagcloudBg">
                            <div className='tagcloudCon'></div>
    		            </div>
                        <div className='SandEtxt'>
                        
                                <h3></h3>
                                <h3></h3>
                                <h3></h3>
                                <h3></h3>
                                <h3></h3>
                                <h3></h3>
                            
                            <br/>
                          
                        </div>
                    </div>
                </motion.div>
                <br/>
                <div className='accordionBtn circle-plus closed' id='Career'>
                    <div className='accordionBtnCircle'>
                        <span><img src="https://img.icons8.com/ios-filled/35/ffffff/online-group-studying.png"/></span>
                    </div>
                    <h4>Career</h4>
                    <div>
                        <div className="circle">
                            <div className="horizontal"></div>
                            <div className="vertical"></div>
                        </div>
                    </div>
                </div>
                <motion.div className='accordionBody educationABody' style={{translateY: panel2YSpring}}>
                    <svg style={{position:'absolute'}}>
                        <filter id='fire'>
                            <feTurbulence id='turbulence' baseFrequency='0.1 0.1' numOctaves='2' seed='3'>
                                <animate attributeName='baseFrequency' dur='5s'
                                    values='0.1 0.1;0.12 0.2' repeatCount='indefinite'>
                                </animate>
                            </feTurbulence>
                            <feDisplacementMap in='SourceGraphic' scale='25'></feDisplacementMap>
                        </filter>
                    </svg>
                    <div className='board'>
                        <div className='boardCon'>
                            <div className='boardTxt'>
                                <h5>We offer our employees:</h5>
                                <h5>👉training and professional development opportunities</h5>
                                <h5>👉employment contract</h5>
                                <h5>👉online service</h5>
                                <h5>👉Work</h5>
                                <h5>👉Punctuality,Liability</h5>
                                <h5>👉willingness to develop professional skills</h5>
                                <h5>👉ability to work in a team</h5>
                                <h5>👉Please send your resumes to </h5>
                                <h5>👉 hiring@repletech.com</h5>
                            </div>
                        </div>
                    </div>
                    <div className='fireBg'>
                        <div className="fire">
                            <div className="fire-left">
                                <div className="main-fire"></div>
                                <div className="particle-fire"></div>
                            </div>
                            <div className="fire-main">
                                <div className="main-fire"></div>
                                <div className="particle-fire"></div>
                            </div>
                            <div className="fire-right">
                                <div className="main-fire"></div>
                                <div className="particle-fire"></div>
                            </div>
                            <div className="fire-bottom">
                                <div className="main-fire"></div>
                            </div>
                        </div>
                        <div className='wstick1 wstick'></div>
                        <div className='wstick2 wstick'></div>
                        <div className='wstick3 wstick'></div>
                    </div>
                </motion.div>
                <br/>
                <div className='accordionBtn circle-plus closed' id='AboutDetails'>
                    <div className='accordionBtnCircle'>
                        <span><img src="https://img.icons8.com/ios-filled/35/ffffff/drawing.png"/></span>
                        </div>
                    <h4>About us</h4>
                    <div>
                        <div className="circle">
                            <div className="horizontal"></div>
                            <div className="vertical"></div>
                        </div>
                    </div>
                </div>
                <motion.div className='accordionBody flipbookABody' style={{translateY: panel3YSpring}}>
                    <HTMLFlipBook width={500} height={500} className="magazine">
                        <div className="sidePage">
                            <span>It's a scratch pad, click to top right / bottom right</span>
                        </div>
                        <div>
                            <div key={0} className='page'
                                style={{background: `url(${DCover})`,
                                        borderRadius: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'goldenrod'}}>
                                <img src={rb} width="80%" alt="Reading Book" height='auto' />
                            </div>
                        </div>
                        <div>
                            <div key={1} className='page innerpage'>
                                <div>
                                    <h6>MOAISUS</h6>                                
                                    <div className='myImg'>
                                        <img src={ayan} />
                                    </div>
                                    <h6>We are end-to-end solutions providing Software Development Company that allows users to get the workplace productivity on virtual, physical and connected environment thus ensuring better workforce management and increased productivity.
                                    </h6>
                                    <h6>With our new-gen technology solutions we ensure you to have a speedy and enhanced business mobilization Your Trusted, Reliable & Dependable Technology Partner through the entire product lifecycle. We deliver #digital success.</h6>
                                </div>
                                <span className='pageNum'>Page 1</span>
                            </div>
                        </div>
                        <div>
                            <div key={2} className='page innerpage innerpagetxt'>
                                <div>
                                    <h6>We are focused not only on cooperation with large companies construction, industrial or investor sectors, but also on the client Private. We guarantee short lead time and competitive prices due to long-term discounts among suppliers Materials.
                                    </h6>
                                    <h6>Our experienced and carefully selected staff ensures the highest quality of the services provided. We work on the best available tools, which guarantees a good and fast work done. We focus on continuous development!
                                    </h6>
                                    <h6>
                                    We are dedicated to help you build the application and technologies that meet your business aspirations. We know the latest versions and libraries and can integrate technologies to satisfy your expectations. Repletech works as your outsourced product development partner and provides end-to-end product development lifecycle management. We brainstorm, design, develop, test and maintain your product as your extended IT team. Our agile methodologies are ideally suitable for the iterative development and better product-market-fit.
                                    </h6>
                                </div>
                                <span className='pageNum'>Page 2</span>
                            </div>
                        </div>
                        
                      
                                           
                                   
                        <div>
                            <div key={7} className='page'
                                style={{background: `url(${DCover})`,
                                        borderRadius: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'goldenrod'}}>
                                <img src={theend} width="80%" alt="The End" height='auto' />
                            </div>
                        </div>
                    </HTMLFlipBook>
                    
                </motion.div>
            </div>
    	</div>
    );
}

export default About;