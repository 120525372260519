import { React } from 'react';
import './p6.css';
import DelayLink from 'react-delay-link';
const P6 = ({props,doo}) => {
    return (
    	<div className="project" id='project6'>
        <DelayLink to='/projectDetails6' delay={1200}>
            <div className='project6 link hello' onClick={doo}>
                <img src='https://www.biospectrumindia.com/uploads/articles/cloud-computing.jpg' />
            </div>
        </DelayLink>
            <h5>Cloud infrastructure</h5>
            <p></p>
    	</div>
    );
}

export default P6;