import { React } from 'react';
import DelayLink from 'react-delay-link';
import './p4.css';
const P4 = ({props,doo}) => {
    return (
    	<div className="project hello" id='project4'>
        <DelayLink to='/projectDetails4' delay={1200}>
            <div className='project4 hello' onClick={doo}>
                <div className='project4con'>
                    <img className='img1' src='https://www.elegantthemes.com/blog/wp-content/uploads/2019/07/cyber-security-home-page.jpg' />
                    <img className='img2' src='https://www.elegantthemes.com/blog/wp-content/uploads/2019/07/cyber-security-landing-page.jpg' />
                </div>
            </div>
        </DelayLink>
            <h5>Cyber Security</h5>
            <p></p>
    	</div>
    );
}

export default P4;