import { React, useEffect } from 'react';
import $ from 'jquery';
import DelayLink from 'react-delay-link';
import anythingZoomer from '../../../node_modules/anythingzoomer/dist/jquery.anythingzoomer.min.js';
import './p1.css';
const P1 = ({props,doo}) => {
    useEffect(()=>{
        $("#zoom2").anythingZoomer();
    },[]);
    return (
        <div className="project ">
        <DelayLink to='/projectDetails1' delay={1200}>            
            <div className="project1 hello" onClick={doo}>
                <div id="zoom2">
                    <div className="small">
                        <img src='https://1.bp.blogspot.com/-7KhIvhfaJnI/XznUVthwBtI/AAAAAAAAAdY/99eTINqDeHYz-FGUKZaK5wyB_EjAzkdfACLcBGAsYHQ/s960/2020-07-22.jpg' />    
                    </div>
                    <div className="large">
                        <img src='https://1.bp.blogspot.com/-7KhIvhfaJnI/XznUVthwBtI/AAAAAAAAAdY/99eTINqDeHYz-FGUKZaK5wyB_EjAzkdfACLcBGAsYHQ/s960/2020-07-22.jpg' />    
                    </div>
                </div>
            </div>
        </DelayLink>
        <h5>Outsourced Product Development</h5>
        <p></p>
        </div>
    );
}

export default P1;