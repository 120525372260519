import { React, useEffect } from 'react';
import { init } from 'ityped';
import './header.css';
import logo from '../../img/png/png/png/logo1.png';
import { HashLink as Link } from 'react-router-hash-link';
const Header = () => {
    useEffect(()=>{
        init('#whatIDo', { showCursor: true, 
                        strings: ['Services!', 'Programming Languagues', 'Full-Stack Developers', 'CRM', 'Cyber Security'],
                        typeSpeed:  110, 
                        backSpeed:  55,
                        backDelay:  3000,
                    })
    },[]);
    
    return (
    	<div className="darkBg section" id="Heading">
            <div className="logo">
                <img src={ logo } alt="logo" />
                <div>
                    <h4>
                        <b>  
                            
                        </b>
                    </h4>
                    <p></p>
                </div>
            </div>
			<div className="lines container-fluid">
                <div className="row h-100">
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                </div>
            </div>
    		
            <div className='headingCard'>
                <br/>
                <h5></h5>
                <div className='whatIDo'><div id='whatIDo'></div> &nbsp;</div>
                <p className='shortIntro'>
                   MOAISUS
                </p>
                <div className='btns'>
                    <div className='link'>
                        <Link smooth to='#About'>
                            Technology Stack
                        </Link>
                    </div>
                    <div className='link'>
                        <Link smooth to='#Projects'>
                            Services
                        </Link>
                    </div>
                </div>
            </div>
    	</div>
    );
}

export default Header;