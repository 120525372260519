import {React, useEffect} from 'react';
import DelayLink from 'react-delay-link';
import './p1.css';
import $ from 'jquery';
import logo from '../../img/png/png/png/logo1.png';
import Contact from '../../section/contact/Contact';

const ProjectDetails5 = ({props, doo}) => {
    useEffect(()=>{
        $('.homeLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Root`;
            },1200);
        });
        $('.workLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Projects`;
            },1200);
        });
        $('.goAboutLink').click(function(){
            doo();
            if(!$('#AboutSandE').hasClass('active')){
				$('#AboutSandE').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#About`;
            },1200);
        });
        $('.AboutDetailsLink').click(function(){
            doo();
            if(!$('#ACademicDetails').hasClass('active')){
				$('#ACademicDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#AboutDetails`;
            },1200);
        });
        $('.contactLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Contact`;
            },1200);
        });
        $('.ACademicDetailsLink').click(function(){
            doo();
            if(!$('#AboutDetails').hasClass('active')){
				$('#AboutDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#ACademicDetails`;
            },1200);
        });
    },[]);
    return(
        <div className='projectDetails1'>
            <div className='section darkBg'>
                <div className="lines container-fluid">
                    <div className="row h-100">
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                </div>
                <DelayLink to='/' delay={1200}>
                    <div className="logo hello" onClick={ doo }>
                        <img src={ logo } alt="logo" />
                        <div>
                            <h4>
                                <b>  
                                    
                                </b>
                            </h4>
                            <p></p>
                        </div>
                    </div>
                </DelayLink>
                
                <div className="Content">
                    <h4>API Development</h4>
                    <p className='toolbelt'>Features : Search by Criteria, Paging, Sorting, JSON, Authorization via OAuth</p>
                    <img className='mainImg' src='https://appinventiv.com/blog/wp-content/uploads/2018/05/Must-Have-Features-of-an-Efficient-API-Development.jpg' />
                    <img className='mainImg' src='https://appinventiv.com/wp-content/uploads/sites/1/2018/05/Complete-Guide-to-API-Development-2.jpg' />
                    <img className='mainImg' src='https://colorwhistle.com/wp-content/uploads/2020/03/Importance-of-API-Development.jpg' />
                    <img className='mainImg' src='https://colorwhistle.com/wp-content/uploads/2020/03/API-Multi-solution-Integration.png' />
                    

                </div>
                <Contact />
            </div>
        </div>
    );
}

export default ProjectDetails5;