import {React, useEffect} from 'react';
import DelayLink from 'react-delay-link';
import './p1.css';
import $ from 'jquery';
import logo from '../../img/png/png/png/logo1.png';
import Contact from '../../section/contact/Contact';

const ProjectDetails3 = ({props, doo}) => {
    useEffect(()=>{
        $('.homeLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Root`;
            },1200);
        });
        $('.contactLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Contact`;
            },1200);
        });
        $('.workLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Projects`;
            },1200);
        });
        $('.goAboutLink').click(function(){
            doo();
            if(!$('#AboutSandE').hasClass('active')){
				$('#AboutSandE').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#About`;
            },1200);
        });
        $('.AboutDetailsLink').click(function(){
            doo();
            if(!$('#ACademicDetails').hasClass('active')){
				$('#ACademicDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#AboutDetails`;
            },1200);
        });
        $('.ACademicDetailsLink').click(function(){
            doo();
            if(!$('#AboutDetails').hasClass('active')){
				$('#AboutDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#ACademicDetails`;
            },1200);
        });
    },[]);
    return(
        <div className='projectDetails1'>
            <div className='section darkBg'>
                <div className="lines container-fluid">
                    <div className="row h-100">
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                </div>
                <DelayLink to='/' delay={1200}>
                    <div className="logo hello" onClick={ doo }>
                        <img src={ logo } alt="logo" />
                        <div>
                            <h4>
                                <b>  
                                    
                                </b>
                            </h4>
                            <p></p>
                        </div>
                    </div>
                </DelayLink>
                
                <div className="Content">
                    <h4>Legacy Application Migration</h4>
                    <p className='toolbelt'>Migration : Prerequisite Gathering, Mock, Failover Testing, Go-Live, Decomm </p>
                    <img className='mainImg' src='https://aqltech.com/wp-content/uploads/2020/05/legacy-application-migration.png' />    
                    <p className='intro middleTxt'>
                    The business world is in the midst of a mass shift to cloud-based IT environments. The recent Gartner surveys show that more than a third of modern companies see cloud adoption as a top investment priority. And according to the 2020 IDG Cloud Computing Survey, 92% of businesses already use the cloud in some capacity, and the number is expected to rise to 95% in a year. 
                    </p>
                    <img className='mainImg' src='https://www.fideltech.com/wp-content/uploads/2020/07/12-Challenges-in-Legacy-Application-Modernization-and-Migration.jpg' />
                    <img className='mainImg' src='https://www.clariontech.com/hs-fs/hubfs/Image5-24.png?width=1030&name=Image5-24.png' />
                    
                                        
                </div>
                <Contact />
            </div>
        </div>
    );
}

export default ProjectDetails3;