import {React, useEffect} from 'react';
import DelayLink from 'react-delay-link';
import './p1.css';
import $ from 'jquery';
import logo from '../../img/png/png/png/logo1.png';
import Contact from '../../section/contact/Contact';

const ProjectDetails1 = ({props, doo}) => {
    useEffect(()=>{
        $('.homeLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Root`;
            },1200);
        });
        $('.contactLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Contact`;
            },1200);
        });
        $('.workLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Projects`;
            },1200);
        });
        $('.goAboutLink').click(function(){
            doo();
            if(!$('#AboutSandE').hasClass('active')){
				$('#AboutSandE').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#About`;
            },1200);
        });
        $('.AboutDetailsLink').click(function(){
            doo();
            if(!$('#ACademicDetails').hasClass('active')){
				$('#ACademicDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#AboutDetails`;
            },1200);
        });
        $('.ACademicDetailsLink').click(function(){
            doo();
            if(!$('#AboutDetails').hasClass('active')){
				$('#AboutDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#ACademicDetails`;
            },1200);
        });
    },[]);
    return(
        <div className='projectDetails1'>
            <div className='section darkBg'>
                <div className="lines container-fluid">
                    <div className="row h-100">
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                </div>
                <DelayLink to='/' delay={1200}>
                    <div className="logo hello" onClick={ doo }>
                        <img src={ logo } alt="logo" />
                        <div>
                            <h4>
                                <b>  
                                    
                                </b>
                            </h4>
                            <p></p>
                        </div>
                    </div>
                </DelayLink>
                
                <div className="Content">
                    <h4>Outsourced Product Development</h4>
                    <p className='toolbelt'>Product : Services, Research, Re-engineering services, Deployment services, Testing services</p>
                    <img className='mainImg' src='http://2.bp.blogspot.com/-rOvdNUbR0uo/VSjDiti08_I/AAAAAAAAATg/typDiqkHD4A/s1600/Outsourced-Product-Development-craterzone.jpg' />    
                    <p className='intro'>The software market becomes more developed and competitive every day. Companies are under tremendous pressure to keep bringing new products to the market. In a globally competitive marketplace, software vendors' efforts to reduce time-to-market are hampered by short product life cycles, obsolescence of production methods as well as the rapid development of design and engineering techniques, as well as an increase in their complexity.
                    </p>
                    <p className='workingDemo'><a href='/' target="_blank">Working Demo</a></p>
                    <p className='description'>
                    Furthermore, product development is a complex activity that requires a lot of effort and time. An easy way for software vendors to produce new versions faster is to outsource product development to an OPD provider. Such a solution provides you with many significant benefits.
                    </p>
                    <img className='mainImg' src='https://brainhub.eu/blog/wp-content/uploads/2020/02/digital-product-development-process-steps.png' />    
                    
                </div>
                <Contact />
            </div>
        </div>
    );
}

export default ProjectDetails1;