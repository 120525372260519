import {React, useEffect} from 'react';
import DelayLink from 'react-delay-link';
import './p1.css';
import $ from 'jquery';
import logo from '../../img/png/png/png/logo1.png';
import Contact from '../../section/contact/Contact';

const ProjectDetails6 = ({props, doo}) => {
    useEffect(()=>{
        $('.homeLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Root`;
            },1200);
        });
        $('.workLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Projects`;
            },1200);
        });
        $('.contactLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Contact`;
            },1200);
        });
        $('.goAboutLink').click(function(){
            doo();
            if(!$('#AboutSandE').hasClass('active')){
				$('#AboutSandE').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#About`;
            },1200);
        });
        $('.AboutDetailsLink').click(function(){
            doo();
            if(!$('#ACademicDetails').hasClass('active')){
				$('#ACademicDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#AboutDetails`;
            },1200);
        });
        $('.ACademicDetailsLink').click(function(){
            doo();
            if(!$('#AboutDetails').hasClass('active')){
				$('#AboutDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#ACademicDetails`;
            },1200);
        });
    },[]);
    return(
        <div className='projectDetails1'>
            <div className='section darkBg'>
                <div className="lines container-fluid">
                    <div className="row h-100">
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                </div>
                <DelayLink to='/' delay={1200}>
                    <div className="logo hello" onClick={ doo }>
                        <img src={ logo } alt="logo" />
                        <div>
                            <h4>
                                <b>  
                                    
                                </b>
                            </h4>
                            <p></p>
                        </div>
                    </div>
                </DelayLink>
                
                <div className="Content">
                    <h4>Cloud Infrastructure</h4>
                    <p className='toolbelt'>Cloud Computing: Online Database</p>
                    <img className='mainImg' src='https://media.itpro.co.uk/image/upload/v1570811813/itpro/images/dir_198/it_photo_99454.jpg' />
                    
                    <p className='middleTxt'>
                    In a cloud computing architecture, cloud infrastructure refers to the back-end hardware elements found within most enterprise data centers, but on much greater scale. These include multisocket, multicore servers, persistent storage and local area network equipment, such as switches and routers. 
                    </p>
                    <img className='mainImg' src='https://ubuntupit.com/wp-content/uploads/2019/09/cloud-computing-on-education.jpg' />
                    <img className='mainImg' src='https://www.utsin.com/wp-content/uploads/2017/12/Cloud-computing.jpg'/>
                     </div>
                <Contact />
            </div>
        </div>
    );
}

export default ProjectDetails6;