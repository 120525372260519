import {React, useEffect} from 'react';
import DelayLink from 'react-delay-link';
import './p1.css';
import $ from 'jquery';
import logo from '../../img/png/png/png/logo1.png';
import Contact from '../../section/contact/Contact';

const ProjectDetails2 = ({props, doo}) => {
    useEffect(()=>{
        $('.homeLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Root`;
            },1200);
        });
        $('.contactLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Contact`;
            },1200);
        });
        $('.workLink').click(function(){
            doo();
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#Projects`;
            },1200);
        });
        $('.goAboutLink').click(function(){
            doo();
            if(!$('#AboutSandE').hasClass('active')){
				$('#AboutSandE').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#About`;
            },1200);
        });
        $('.AboutDetailsLink').click(function(){
            doo();
            if(!$('#ACademicDetails').hasClass('active')){
				$('#ACademicDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#AboutDetails`;
            },1200);
        });
        $('.ACademicDetailsLink').click(function(){
            doo();
            if(!$('#AboutDetails').hasClass('active')){
				$('#AboutDetails').click();
			}
            setTimeout(function(){
                window.location.href=`/Portfolio/#/#ACademicDetails`;
            },1200);
        });
    },[]);
    return(
        <div className='projectDetails1'>
            <div className='section darkBg'>
                <div className="lines container-fluid">
                    <div className="row h-100">
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                </div>
                <DelayLink to='/' delay={1200}>
                    <div className="logo hello" onClick={ doo }>
                        <img src={ logo } alt="logo" />
                        <div>
                            <h4>
                                <b>  
                                    
                                </b>
                            </h4>
                            <p></p>
                        </div>
                    </div>
                </DelayLink>
                
                <div className="Content">
                    <h4>Mobile App Development</h4>
                    <p className='toolbelt'>Application : Services, Research,  Deployment services, Testing services</p>
                    <img className='mainImg' src='https://www.panaceatek.com/wp-content/uploads/2017/06/Analysis-of-Different-Phases-of-Mobile-App-Development.jpg' />    
                    <p className='intro middleTxt'>
                    Mobile app development is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones. These applications can be pre-installed on phones during manufacturing platforms, or delivered as web applications using server-side or client-side processing (e.g., JavaScript) to provide an "application-like" experience within a Web browser.
                    </p>
                    <img className='mainImg' src='https://www.sitcomtech.com/img/iphoneappp.gif' />
                    <p className='description middleTxt'>
                    Application software developers also must consider a long array of screen sizes, hardware specifications, and configurations because of intense competition in mobile software and changes within each of the platforms. Mobile app development has been steadily growing, in revenues and jobs created. 
                    </p>
                    <img className='mainImg' src='https://1stwebdesigner.com/wp-content/uploads/2015/10/Delete-task-and-assign-task-to-your-teammate-in-action.gif' />
                    <p className='description middleTxt'>
                    Mobile UIs, or front-ends, rely on mobile back-ends to support access to enterprise systems. The mobile back-end facilitates data routing, security, authentication, authorization, working off-line, and service orchestration.  
                    </p>
                    <img className='mainImg' src='https://lh3.googleusercontent.com/U16-_7BYbQ56eS1WkAQHWXLUoOTV0YoxcKcfW7ZY6z30gMNsp_fcc8EfDxzeZyOc9BuxOvA5FmMdOK2fI3T-BaqtThMKHHcZEb9Hhj0DJQsm-FHcINR853MBHUZuvxtNDkfrPmWacA=w2400' /> 
                    
                    
                    
                </div>
                <Contact />
            </div>
        </div>
    );
}

export default ProjectDetails2;