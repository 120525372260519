import { React } from 'react';
import DelayLink from 'react-delay-link';
import './p5.css';
const P5 = ({props,doo}) => {
    return (
    	<div className="project" id='project5'>
        <DelayLink to='/projectDetails5' delay={1200}>
            <div className='project5 link hello' onClick={doo}>
                <img className='img1' src='https://www.okta.com/sites/default/files/images/datasheets/report-thumb/DTS_Okta_CIAM_APIAM_FULL_Page_1.png' />
                <img className='img2' src='https://appinventiv.com/blog/wp-content/uploads/2018/05/Must-Have-Features-of-an-Efficient-API-Development.jpg' />
            </div>
        </DelayLink>
            <h5>API Development</h5>
            <p></p>
    	</div>
    );
}

export default P5;